import { ScrollCaptain } from 'scroll-captain';
import './stage.scss';

class Stage {
    constructor (element, options) {
        // Options
        const defaults = {
            initAttr: 'data-stage',
            triggerSuffix: 'root',
            circleValue: 'circle',
            playPauseToggleValue: 'playe-pause-toggle',
            pausedAttr: 'data-stage-paused',
            videoStateValue: 'video-state'
        };

        this.settings = Object.assign({}, defaults, options);
        
        // DOM Elements
        this.$root = element;
        this.$circle = document.querySelector(`[${this.settings.initAttr}="${this.settings.circleValue}"]`);
        this.$video = this.$root.querySelector('video');
        this.$videoState = this.$root.querySelector(`[${this.settings.initAttr}="${this.settings.videoStateValue}"]`);
        this.$playPauseToggle = this.$root.querySelector(`[${this.settings.initAttr}="${this.settings.playPauseToggleValue}"]`);

        // Settings
        this.animation = null;
    }

    initialize () {        
        this.loadAnimation();
        this.setEvents();

        if (this.$playPauseToggle && this.$video) {
            this.toggleVideo();
        }
    }
    
    toggleVideo () {
        this.$playPauseToggle.addEventListener('click', () => {
            if (!this.$video.paused && !this.$video.ended && this.$video.readyState > 2) {
                this.$root.setAttribute(this.settings.pausedAttr, '');
                this.$video.pause();

                if (this.$videoState) {
                    this.$videoState.textContent = 'Video is paused.';
                }
            } else {
                this.$root.removeAttribute(this.settings.pausedAttr, '');
                this.$video.play();

                if (this.$videoState) {
                    this.$videoState.textContent = 'Video is playing.';
                }
            }
        });
    }
    
    loadAnimation () {        
        this.animation = new ScrollCaptain(document.body, {
            triggerSuffix: this.settings.triggerSuffix,
            initAttr: this.settings.initAttr,
            bottom: `${(window.innerHeight * -1) + 136}px`,
            top: '-50%',
            updateOnResize: () => {
                this.animation.defaultOptions.bottom= `${(window.innerHeight * -1) + 136}px`;
                this.animation.defaultOptions.top= `${this.$circle.clientHeight * -1}px`;
            },
            animateCircle: {
                targetSuffix: this.settings.circleValue,
                onscroll: true,
                translateY: window.reducedMotionActive ? null : 1000,
                translateX: window.reducedMotionActive ? null : -500,
                opacity: { 0: 1, 100: 0 }
            }
        });
    }
    
    updateAnimation (updateRequired) {
        this.animation.defaultOptions.animateCircle.translateY = window.reducedMotionActive ? null : 1000;
        this.animation.defaultOptions.animateCircle.translateX = window.reducedMotionActive ? null : -500;

        if (updateRequired) {
            this.animation.update();
        }
    }

    setEvents () {
        window.addEventListener('motionPreferenceChanged', () => {
            this.updateAnimation(true);
        });
    }
}

export { Stage };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        const $$roots = $context.querySelectorAll('[data-stage="root"]');
        $$roots.forEach($root => {
            const $rootAPI = new Stage($root);
            $rootAPI.initialize();
        });
    }
});
